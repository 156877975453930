import React from "react";

import styles from './WeightedRate.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import Progressbar from "../../../../Shared/Progressbar/Progressbar";
import general from "../../../../../functions/general";

function WeightedRate(props: WeightedRateProps) {
    const targetDefault = 0;
    const valueDefault = 0;

    return (
        <div className={styles.weightedrate}>
            <ContentCard
                title="Snitt vektet rente siste 3 måneder"
                open={true}
                disableClick={true}
                loading={props.data === undefined}
            >
                <Progressbar
                    data={{
                        markers: props.data?.target ? [
                            {
                                value: (props.data?.target ?? targetDefault) * 100,
                                label: "Snitt alle forhandlere: " + general.formatPercentage(Number(((props.data?.target ?? targetDefault) * 100).toFixed(2))),
                                labeloffset: -100
                            },
                        ] : [],
                        min: 0,
                        max: Math.max(((props.data?.target ?? targetDefault) * 100) + 2, ((props.data?.value ?? valueDefault) * 100) + 2, 10),
                        value: (props.data?.value ?? valueDefault) * 100,
                        label: general.formatPercentage(Number(((props.data?.value ?? valueDefault) * 100).toFixed(2))),
                        labeloffset: -15,
                        minLabel: "0",
                        maxLabel: Math.max(((props.data?.target ?? targetDefault) * 100) + 2, ((props.data?.value ?? valueDefault) * 100) + 2, 10).toString(),
                    }}
                ></Progressbar>
            </ContentCard>
        </div>
    );
}

interface WeightedRateProps {
    data: {
        target: number,
        value: number
    } | undefined
}

export default WeightedRate;